



































































































































.boxFile {
  .full-width {
    width: 100% !important;
    height: auto !important;
  }
  .input-group {
    background-color: #3b5998;
    border-color: 1px solid #3b5998;
    border-radius: 0.3rem;
    color: #fff;
    max-width: 180px;
    input[type='file'] {
      cursor: pointer;
    }
    &::before {
      position: absolute;
      top: 9px;
      left: calc(50% - 45px);
      font-weight: 600;
      color: #fff;
      pointer-events: none;
      content: 'Upload File';
    }
    &:hover {
      background-color: #30497c;
      border-color: #2d4373;
      &::before {
        color: #fff;
      }
    }
  }
  &.uploading {
    .input-group {
      opacity: .7;
      &::before {
        content: '';
        left: calc(50% - 0.75rem);
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: text-bottom;
        border: 0.25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border 0.75s linear infinite;
        animation: spinner-border 0.75s linear infinite;
      }
    }
  }
  .form-control {
    opacity: 0;
  }
}
